import SearchRequirementComponent from "./SearchRequirementComponent";
import {useEffect, useState} from "react";
import {
    createDefaultSearchRequirement,
    shouldRenderAnotherHero,
    shouldRenderItem,
    shouldRenderNumericValueInput
} from "./commons";
import {SearchRequirement} from "./types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus, faSearch} from '@fortawesome/free-solid-svg-icons'
import { useNavigate } from "react-router-dom";
import {DotaActionName} from "./DotaActionsEnum";
import ReactGA from 'react-ga4';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

interface DateRange {
    afterDate: string;
    beforeDate: string;
}

interface Query {
    dateRange?: DateRange;
    multipleAnyHeroRefersToSameHero: boolean;
    searchRequirements: SearchRequirement[];
}

interface SearchRequirementFromAI {
    hero: string;
    action: keyof typeof DotaActionName;
    startTime: number;
    endTime: number;
    recipient?: string;
    value?: number | string;
}

interface QueryCreated {
    dateRange?: DateRange;
    multipleAnyHeroRefersToSameHero?: boolean;
    searchRequirements?: SearchRequirementFromAI[];
}

interface OpenAIResponse {
    actionRecognised: boolean;
    queryCreated?: QueryCreated;
}

const NewQueryComponent = () => {

    const [searchRequirements, setSearchRequirements] = useState<SearchRequirement[]>([createDefaultSearchRequirement()]);
    const [heroes, setHeroes] = useState<string[]>([]);
    const [items, setItems] = useState<string[]>([]);
    const [multipleAnyHeroRefersToSameHero, setMultipleAnyHeroRefersToSameHero] = useState<boolean>(false);
    const [priorityCode, setPriorityCode] = useState<string | undefined>();
    const [showPriorityCode, setShowPriorityCode] = useState<boolean>(false);
    const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([null, null]);
    const [startDate, endDate] = dateRange;
    const navigate = useNavigate()
    const [activeTab, setActiveTab] = useState<'manual' | 'prompt'>('manual');
    const [promptText, setPromptText] = useState<string>('');
    const [promptWarning, setPromptWarning] = useState<string | null>(null);

    ReactGA.send({
     hitType: "pageview",
     page: "/",
     title: "New Query"
    });

    useEffect(() => {
        fetch('/api/heroes')
            .then(response => response.json())
            .then(data => {
                setHeroes(data);
            })
    }, []);
    useEffect(() => {
        fetch('/api/items')
            .then(response => response.json())
            .then(data => {
                setItems(data);
            })
    }, []);

    useEffect(() => {
        setPromptWarning(null);
    }, [activeTab]);

    const modifySearchRequirement = (searchRequirementIdx: number): (updatedSearchReq: SearchRequirement) => void => {
        const updateFunction: (updatedSearchReq: SearchRequirement) => void = (updatedSearchRequirement: SearchRequirement) => {
            const newSearchRequirements = [...searchRequirements];
            newSearchRequirements[searchRequirementIdx] = updatedSearchRequirement;
            setSearchRequirements(newSearchRequirements);
        }
        return updateFunction;
    }

    const deleteSearchRequirement = (searchRequirementIdx: number): () => void => {
        const updateFunction: () => void = () => {
            const newSearchRequirements = [...searchRequirements];
            newSearchRequirements.splice(searchRequirementIdx, searchRequirementIdx);
            setSearchRequirements(newSearchRequirements);
        }
        return updateFunction;
    }

    const addNewSearchRequirement = (): void => {
        const newSearchRequirements = [...searchRequirements];
        newSearchRequirements.push(createDefaultSearchRequirement());
        setSearchRequirements(newSearchRequirements);
    }

    const validateQuery = (query: Query): boolean => {
        if (query.dateRange?.afterDate && !query.dateRange?.beforeDate) {
            alert("End date not selected");
            return false;
        }
        if (!query.dateRange?.afterDate && query.dateRange?.beforeDate) {
            alert("Start date not selected");
            return false;
        }

        return query.searchRequirements.every((requirement) => {
            if (requirement.hero === "Select a hero") {
                alert("Hero not selected in one of the requirements")
                return false;
            }
            if (requirement.action === undefined) {
                alert("Action not selected in one of the requirements")
                return false;
            }
            if (requirement.endTime === 0) {
                alert("Game time not selected or the end time is 00:00:00 in one of the requirements")
                return false;
            }
            if (shouldRenderNumericValueInput(requirement.action) && requirement.value === undefined) {
                alert("Value not selected in one of the requirements")
                return false;
            }
            if (shouldRenderAnotherHero(requirement.action) && requirement.recipient === undefined) {
                alert("Recipient hero not selected in one of the requirements")
                return false;
            }
            if (shouldRenderItem(requirement.action) && requirement.recipient === undefined) {
                alert("Item not selected in one of the requirements")
                return false;
            }
            return true;
        });
    }

    const validateQuerySendAndRedirect = (providedQuery?: Query) => {
        const query = providedQuery || {
            dateRange: startDate && endDate ? { 
                afterDate: startDate.toISOString(), 
                beforeDate: endDate.toISOString() 
            } : undefined,
            multipleAnyHeroRefersToSameHero,
            searchRequirements
        };

        if (!validateQuery(query)) {
            return;
        }

        const requestBody: any = {
            searchRequirements: query.searchRequirements,
            multipleAnyHeroRefersToSameHero: query.multipleAnyHeroRefersToSameHero,
            priorityCode: priorityCode
        };

        if (query.dateRange) {
            requestBody.dateRange = query.dateRange;
        }

        fetch(`/api/query`, {
            method: 'POST',
            headers: {'Content-Type':'application/json'},
            body: JSON.stringify(requestBody)
        }).then((response) => {
            if (response.ok) {
                return response.text();
            }
            throw new Error(`Error creating query, status: ${response.status}`);
        }).then((uuid) => {
            navigate(`/query/${uuid}`);
        }).catch((e) => {
            alert("Unable to create a query for some reason")
        })
    }

    const checkPromptAndRedirectIfSuccessful = () => {
        fetch('/api/prompt', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ prompt: promptText })
        }).then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        }).then((data: OpenAIResponse) => {
            if (!data.actionRecognised) {
                setPromptWarning("Unable to parse prompt");
                return;
            }
            
            if (data.queryCreated?.searchRequirements) {
                const query: Query = {
                    dateRange: data.queryCreated.dateRange,
                    multipleAnyHeroRefersToSameHero: data.queryCreated.multipleAnyHeroRefersToSameHero || false,
                    searchRequirements: data.queryCreated.searchRequirements.map(aiReq => ({
                        hero: aiReq.hero,
                        action: aiReq.action as keyof typeof DotaActionName,
                        startTime: aiReq.startTime,
                        endTime: aiReq.endTime,
                        recipient: aiReq.recipient,
                        value: aiReq.value as string
                    }))
                };

                if (!validateQuery(query)) {
                    setPromptWarning("Generated query is invalid");
                    return;
                }

                validateQuerySendAndRedirect(query);
            }
        }).catch(error => {
            console.error('Error:', error);
            setPromptWarning("An error occurred while processing your request");
        });
    }

    return (
        <>
            <div className="descriptionWrapper mt-5 mb-5">
                <h1 className="text-center header-font" style={{fontFamily: 'Merienda', fontWeight: 800}}>DOTA REPLAY
                    FINDER</h1>
                <p className="text-center" style={{fontSize: "1.17em", paddingTop: "0px", marginBottom: "2px"}}>Search high MMR and pro
                    matches in which specific events happened</p>
            </div>
            <div className="container">                
                <ul className="nav nav-tabs mb-4" style={{ borderBottom: '1px solid #C7C8C9' }}>
                    <li className="nav-item">
                        <button 
                            className={`nav-link ${activeTab === 'manual' ? 'active' : ''}`}
                            onClick={() => setActiveTab('manual')}
                            style={{ 
                                color: activeTab === 'manual' ? '#000' : '#6c757d',
                                borderColor: activeTab === 'manual' ? '#C7C8C9 #C7C8C9 transparent #C7C8C9' : 'transparent',
                                backgroundColor: activeTab === 'manual' ? '#ffc107' : 'transparent'
                            }}
                        >
                            Build a query manually
                        </button>
                    </li>
                    <li className="nav-item">
                        <button 
                            className={`nav-link ${activeTab === 'prompt' ? 'active' : ''}`}
                            onClick={() => setActiveTab('prompt')}
                            style={{ 
                                color: activeTab === 'prompt' ? '#000' : '#6c757d',
                                borderColor: activeTab === 'prompt' ? '#C7C8C9 #C7C8C9 transparent #C7C8C9' : 'transparent',
                                backgroundColor: activeTab === 'prompt' ? '#ffc107' : 'transparent'
                            }}
                        >
                            Create a prompt
                        </button>
                    </li>
                </ul>

                {activeTab === 'manual' ? (
                    <>
                        <p className="text-center" style={{fontSize: "1.95em", fontWeight: "bolder"}}>List your search
                            criteria</p>
                        
                        <div className="white-placeholder-wrapper">
                            <DatePicker
                                selectsRange={true}
                                startDate={startDate}
                                endDate={endDate}
                                onChange={(update) => {
                                    const [start, end] = update;
                                    if (start) {
                                        start.setHours(0, 0, 0, 0);
                                    }
                                    if (end) {
                                        end.setHours(23, 59, 59, 999);
                                    }
                                    setDateRange([start, end]);
                                }}
                                maxDate={new Date()}
                                minDate={new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)}
                                isClearable={true}
                                className="form-control"
                                placeholderText="Games from last 7 days"
                                customInput={
                                    <input
                                        type="text"
                                        className="btn btn-secondary"
                                    />
                                }
                            />
                        </div>

                        {searchRequirements.map((searchRequirement, index) => (
                            <>
                                <SearchRequirementComponent
                                    searchRequirement={searchRequirement}
                                    changeSearchRequirement={modifySearchRequirement(index)}
                                    deleteSearchRequirement={deleteSearchRequirement(index)}
                                    heroes={heroes}
                                    items={items}
                                    key={`search_requirement_${index}`}
                                />
                            </>
                        ))}
                        <div>
                            <div className="form-group">
                                <div className="dropdown">
                                    <button className="btn btn-secondary" onClick={addNewSearchRequirement}>
                                        <FontAwesomeIcon className="fa-solid" icon={faPlus} size="lg"/>
                                    </button>
                                </div>
                            </div>
                        </div>
                        {searchRequirements.map(searchRequirement => searchRequirement?.hero).filter(heroName => heroName === 'Any hero').length >= 2 &&
                            <div className="form-check mt-2">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    value=""
                                    id="flexCheckDefault"
                                    onChange={() => {
                                        setMultipleAnyHeroRefersToSameHero(!multipleAnyHeroRefersToSameHero);
                                    }}
                                />
                                <label className="form-check-label" htmlFor="flexCheckDefault">
                                    Multiple requirements regarding <b>Any hero</b> should refer to the same hero
                                </label>
                            </div>
                        }
                    </>
                ) : (
                    <div>
                        <textarea
                            className="form-control"
                            rows={5}
                            placeholder={`e.g: Sf gets a tripple kill`}
                            style={{ marginBottom: '20px' }}
                            onChange={(e) => {
                                setPromptText(e.target.value);
                                setPromptWarning(null);
                            }}
                        />
                        {promptWarning && (
                            <div className="alert alert-danger" style={{ marginTop: '-15px' }}>
                                <p>{promptWarning}</p>
                                <hr/>
                                <div className="accordion" id="actionsAccordion">
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button 
                                                className="accordion-button collapsed"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#collapseActions"
                                                aria-expanded="false"
                                                aria-controls="collapseActions"
                                            >
                                                View all supported events
                                            </button>
                                        </h2>
                                        <div 
                                            id="collapseActions"
                                            className="accordion-collapse collapse"
                                            data-bs-parent="#actionsAccordion"
                                        >
                                            <div className="accordion-body">
                                                <ul className="list-unstyled mb-0">
                                                    {Object.values(DotaActionName).map((action, index) => (
                                                        <li key={index}>{action}</li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                )}

                <hr/>
                <div className="form-check mt-2">
                    <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        id="promocodeCheck"
                        onChange={() => {
                            setShowPriorityCode(!showPriorityCode);
                        }}
                    />
                    <label className="form-check-label" htmlFor="promocodeCheck">
                        I have a priority processing code
                    </label>
                    {showPriorityCode &&
                        <>
                            <br/>
                            <input
                                type="text"
                                className="form-control mt-3"
                                placeholder="Enter your priority Code"
                                onChange={(e) => {
                                    setPriorityCode(e.target.value);
                                }}
                            />
                        </>
                    }
                </div>
            </div>
            <div className="text-center my-5">
                <button type="button" className="btn btn-warning btn-lg">
                    <div onClick={() => {
                        if (activeTab === 'manual') {
                            validateQuerySendAndRedirect();
                        } else {
                            checkPromptAndRedirectIfSuccessful();
                        }
                    }}>
                        <FontAwesomeIcon icon={faSearch} size="lg"/>
                        {` Search for such replays`}
                    </div>
                </button>
            </div>
        </>
    )
}

export default NewQueryComponent;
