import {useEffect, useState} from "react";
import {useParams, useSearchParams} from "react-router-dom";
import {faTrashCan} from "@fortawesome/free-solid-svg-icons/faTrashCan";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFaceFrown, faSpinner, faClock} from "@fortawesome/free-solid-svg-icons";
import {DotaActionName} from "./DotaActionsEnum";
import ReactGA from 'react-ga4';
import MatchResult from "./MatchResult";

const NewQueryComponent = () => {

    ReactGA.send({
     hitType: "pageview",
     page: "/query",
     title: "Result"
    });

    interface ReplayQueryEntity {
        status: ProcessingStatus,
        uuid: string,
        anyHeroQueriesReferToSameHero: boolean,
        satisfiedRequirementDetailsPerReplay: Map<string, SatisfiedRequirementDetails[]>,
        displayableSearchRequirements: DisplayableSearchRequirement[]
    }

    interface DisplayableSearchRequirement {
        hero:string,
        action: DisplayableDotaAction,
        startTime: string,
        endTime: string
    }

    interface SatisfiedRequirementDetails {
        hero: string,
        dotaAction: DisplayableDotaAction,
        timestamp: string,
    }

    interface DisplayableDotaAction {
        descriptiveActionName: string,
        additionalActionDetails: string
    }

    type ProcessingStatus = "processing" | "unprocessed" | "processed";

    const {queryId} = useParams();
    const [searchResult, setSearchResult] = useState<ReplayQueryEntity>({} as ReplayQueryEntity);
    const [matches, setMatches] = useState<Map<string, ReplayQueryEntity>>(new Map());

    useEffect(() => {
        fetchDataPeriodically();
    }, []);

    var retryCounter = 1;

    const fetchDataPeriodically = () => {
        const fetchData = () => {
            fetch(`/api/query/${queryId}`)
                .then(response => response.json())
                .then(data => {
                    setSearchResult(prevResult => {
                        if (prevResult.satisfiedRequirementDetailsPerReplay && data.satisfiedRequirementDetailsPerReplay) {
                            // Preserve existing matches and add new ones
                            return {
                                ...data,
                                satisfiedRequirementDetailsPerReplay: {
                                    ...prevResult.satisfiedRequirementDetailsPerReplay,
                                    ...data.satisfiedRequirementDetailsPerReplay
                                }
                            };
                        }
                        return data;
                    });
                    clearIntervalIfNeeded(data.status);
                })
        }

        const refetchIntervalId = setInterval(() => {
            fetchData();
        }, Math.min(10000, 2000 * ++retryCounter));


        const clearIntervalIfNeeded = (status: ProcessingStatus) => {
            if (status === 'processed') {
                clearInterval(refetchIntervalId);
            }
        }

        fetchData();
    }


    const displayProcessingMessage = (status: ProcessingStatus, matchingReplays: string[]) => {
        if (status === 'unprocessed') {
            return (
                <div className="container text-center my-5">
                    <FontAwesomeIcon icon={faClock} size="5x"/>
                    <h5>Query added to queue. This might take a while. The page will automatically refresh once the query starts processing. You can copy the URL and come back to it later</h5>
                </div>
            )
        }
        if (status === 'processing' && matchingReplays.length === 0) {
            return (
                <div className="container text-center my-5">
                    <FontAwesomeIcon icon={faSpinner} className="fa-spin fa-pulse" size="5x"/>
                    <h5>Processing is ongoing. This might take a while. The page will automatically refresh once the query is processed. You can copy the URL and come back to it later</h5>
                </div>
            )
        }
        if (status === 'processing' && matchingReplays.length > 0) {
            return (
                <div className="container text-center my-5">
                    <FontAwesomeIcon icon={faSpinner} className="fa-spin fa-pulse" style={{color: "#99edc3"}} size="5x"/>
                    <h5>Some matching replays were found. Processing is still ongoing. More results might appear later.</h5>
                </div>
            )
        }
        if (status === 'processed' && matchingReplays.length === 0) {
            return (
                <div className="container text-center my-5">
                    <FontAwesomeIcon icon={faFaceFrown} size="5x"/>
                    <h5>No replays satisfying the requirements were found</h5>
                </div>
            )
        }
    }

    const timestampToDateFormat = (timestampSeconds: string) => {
        const date = new Date(0);
        date.setSeconds(Number(timestampSeconds));
        return date.toISOString().substring(11, 19);
    }

    function createReplayAccordions() {
        return (
            <>
                <div className="accordion accordion-result">
                    {Object.entries(searchResult?.satisfiedRequirementDetailsPerReplay || []).map(([replayId, requirementDetails]: [string, SatisfiedRequirementDetails[]]) => {
                        return (
                            <div className="accordion-item my-3">
                                <h2 className="accordion-header" >
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                            data-bs-target={`#collapse-${replayId}`} aria-expanded="true" aria-controls={`collapse-${replayId}`}>
                                        {`Replay ${replayId} satisfies your requirements. Toggle for further details`}
                                    </button>
                                </h2>
                                <div id={`collapse-${replayId}`} className="accordion-collapse collapse">
                                    <div className="accordion-body">
                                        <h2>ID: {`${replayId}`}</h2>
                                        <MatchResult match={{id: replayId}} />
                                        {requirementDetails.map((requirement) => {
                                            return (
                                                <div>
                                                    {`At game time ${timestampToDateFormat(requirement.timestamp)} ${requirement.hero} ${requirement.dotaAction.descriptiveActionName} ${requirement.dotaAction.additionalActionDetails ?? ''}`}
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </>
        );
    }

    function createRequirementsAccordion() {
        return (
            <div className="accordion accordion-requirement">
                <div className="accordion-item my-3 ">
                    <h2 className="accordion-header ">
                        <button className="accordion-button " type="button" data-bs-toggle="collapse"
                                data-bs-target={`#collapse-requirements`} aria-expanded="true"
                                aria-controls={`collapse-requirements`}>
                            <h5>Your requirements</h5>
                        </button>
                    </h2>
                    <div id={`collapse-requirements`} className="accordion-collapse collapse show">
                        <div className="accordion-body">
                            {(searchResult?.displayableSearchRequirements || []).map((displayableSearchRequirement: DisplayableSearchRequirement) => {
                                return (
                                    <div>
                                        {`[${timestampToDateFormat(displayableSearchRequirement.startTime)}-${timestampToDateFormat(displayableSearchRequirement.endTime)}] ${displayableSearchRequirement.hero} ${displayableSearchRequirement.action.descriptiveActionName} ${displayableSearchRequirement.action.additionalActionDetails ?? ''}`}
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="descriptionWrapper mt-5 mb-5">
            <h1 className="text-center header-font" style={{fontFamily: 'Merienda', fontWeight: 800}}>DOTA REPLAY
                FINDER</h1>
            <div className="container text-center my-5">
                {createRequirementsAccordion()}
                <h1>{`Query ID : ${queryId}`}</h1>
                {displayProcessingMessage(searchResult?.status, searchResult?.satisfiedRequirementDetailsPerReplay ? Object.keys(searchResult.satisfiedRequirementDetailsPerReplay) : [])}
                {createReplayAccordions()}
            </div>
        </div>
    )
}

export default NewQueryComponent;
