import './MatchResult.css';
import opendotaIcon from '../img/opendotafavicon.png';
import dotabuffIcon from '../img/dotabufffavicon.png';
import stratzIcon from '../img/stratzfavicon.png';

interface MatchResultProps {
    match: {
        id: string;
    };
}

const MatchResult: React.FC<MatchResultProps> = ({ match }) => {
    return (
        <div className="match-result">
            <div className="external-links">
                <a 
                    href={`https://www.opendota.com/matches/${match.id}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="external-link"
                >
                    <img src={opendotaIcon} alt="OpenDota" title="View on OpenDota" />
                </a>
                <a 
                    href={`https://www.dotabuff.com/matches/${match.id}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="external-link"
                >
                    <img src={dotabuffIcon} alt="Dotabuff" title="View on Dotabuff" />
                </a>
                <a 
                    href={`https://stratz.com/matches/${match.id}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="external-link"
                >
                    <img src={stratzIcon} alt="STRATZ" title="View on STRATZ" />
                </a>
            </div>
        </div>
    );
}

export default MatchResult; 