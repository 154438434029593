import './App.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import {Route, Routes, useNavigate} from 'react-router-dom';
import NewQueryComponent from "./search-query/NewQueryComponent";
import QueryResultComponent from "./search-query/QueryResultComponent";
import React, {useState} from "react";
import ReactGA from 'react-ga4';
import IntroComponent from "./search-query/IntroComponent";

function App() {
  const navigate = useNavigate();
  const [showContactModal, setShowContactModal] = useState(false);

  const getEmail = () => {
    const chars = [
        '\x6c\x79\x75\x62\x6f\x6d\x69\x72', // lyubomir
        '\x64\x6f\x74\x61\x72\x65\x70\x6c\x61\x79\x66\x69\x6e\x64\x65\x72', // dotareplayfinder
        '\x63\x6f\x6d' // com
    ].map(x => 
        x.split('').map(c => String.fromCharCode(c.charCodeAt(0)))
         .join('')
    );
    
    return `${chars[0]}@${chars[1]}.${chars[2]}`;
  };

  ReactGA.initialize('G-F7HPJSKHGP',
       {
            gaOptions: {
              debug: true,
              titleCase: false,
              siteSpeedSampleRate: 100
            }
       }
   );

  return (
      <>
        <nav className="navbar navbar-expand-lg navbar-light" style={{backgroundColor: '#A6B8C9'}}>
          <div className="container-fluid">
            <a className="navbar-brand general-header" href="/">Dota Replay Finder</a>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                    data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                    aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"/>
            </button>
            <br/>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
              <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                <li className="nav-item">
                  <a className="nav-link active" aria-current="page" href="/">New Query</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link active" aria-current="page" href="/intro">Get Started</a>
                </li>
              </ul>
              <ul className="navbar-nav">
                <li className="nav-item">
                  <a className="nav-link active" aria-current="page" href="#" 
                     onClick={(e) => {
                         e.preventDefault();
                         setShowContactModal(true);
                     }}>
                    Contact & Feedback
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>

        {/* Contact Modal */}
        <div className={`modal ${showContactModal ? 'show' : ''}`} 
             style={{ display: showContactModal ? 'block' : 'none' }}
             tabIndex={-1}>
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Contact Us</h5>
                <button type="button" className="btn-close" onClick={() => setShowContactModal(false)}></button>
              </div>
              <div className="modal-body">
                <p>This is a personal project that's currently not making enough money to sustain itself. It's going to be running at least until June while we're figuring out how to make it sustainable.</p>
                <p>Feedback would be very important in our decision how to move forward. Please share your experience even if it's as simple as "I use it" or "It doesn't provide what I need".</p>
                <p>It's only a side project for the time being but we'll do our best to respond to your email within a week.</p>
                <p>For or partnership opportunities, please contact:</p>
                <p className="text-center fw-bold">
                  <a href={`mailto:${getEmail()}`}>
                    {getEmail()}
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
        {showContactModal && <div className="modal-backdrop fade show"></div>}

        <Routes>
          <Route path="/" element={<NewQueryComponent />} />
          <Route path="/query/:queryId" element={<QueryResultComponent />} />
          <Route path="/intro" element={<IntroComponent />} />
        </Routes>
      </>
  );
}

export default App;
